import React from "react";

import Layout from "components/Layout";
import SEO from "components/SEO";
import Posts from "components/Posts";
import VideoGames from "components/VideoGames";

const VideoGamesPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Work" />
      <VideoGames />
    </Layout>
  );
};

export default VideoGames;
